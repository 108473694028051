import { Fragment } from "react";
import Features from "../Features";
import Hero from "../Hero";
import Zigzag from "../Zigzag";
import Newsletter from "../Newsletter";

export default function Product() {
    return (
        <Fragment>
            <Hero />
            <Features />
            <Zigzag />
            <Newsletter />
        </Fragment>
    )
}