import { useCallback, useState } from "react";
import BlogThumb from "./BlogThumb";

export default function Blog() {
    const [ selected, setSelected ] = useState('');

    const categories = [ "All", "Mix/Mastering", "History", "Etc." ];
  
    const TitleStyle = useCallback(( item ) => (
      `hover:text-gray-600 cursor-pointer ${selected.startsWith(item) && "text-gray-400"}`
    ), [ selected ]);
  
    const onClickMenu = useCallback(( item ) => {
        if (selected === item) {
            setSelected("");
        } else {
            setSelected(item);
        }
    }, [ setSelected, selected ]);

    const MenuItems = useCallback(( { item } ) => (
        <p className={TitleStyle(item)} onClick={(() => onClickMenu(item))}>{item}</p>
    ), [ TitleStyle, onClickMenu ]);

    return (
        <section>
            <div className="max-w-6xl mx-auto px-4 sm:px-6 mt-20 relative">
                <div className="h1 my-10">Blog</div>
                <div className="flex gap-10 justify-start items-center list-none my-10">
                    {categories.map(( item, idx ) => (
                        <MenuItems key={idx} item={item} />
                    ))}
                </div>
                <div className="grid grid-cols-2 md:grid-cols-4 xsm:grid-cols-2 gap-7">
                    <BlogThumb title="유징아 블로그 써" body="제발" writer="정홍주" timestamp="24-10-07" />
                    <BlogThumb title="알겠어 누나" body="얼른 쓸게" writer="착한 유진이" timestamp="24-10-08" />
                </div>
            </div>
        </section>
    )
}