import React, { Fragment, useCallback, useState } from 'react';
import Logo from '../images/logo.png';
import { useNavigate } from 'react-router-dom';

export default function Navbar() {
  const [ selected, setSelected ] = useState('/');
  const [ subMenu, setSubMenu ] = useState('/');
  let navigate = useNavigate();

  const NavComponentStyle = `relative`;

  const TitleStyle = useCallback(( url ) => (
    `hover:text-gray-600 cursor-pointer ${selected.startsWith(url) && "text-gray-400"}`
  ), [ selected ]);

  const onClickMenu = useCallback(( url ) => {
    setSelected(url);
    navigate(url);
  }, [ setSelected ]);

  const NavComponent = useCallback(({ url, text, components = undefined }) => (
    <div className={ NavComponentStyle }>
      <p className={TitleStyle(url)} onClick={() => onClickMenu(url)} onMouseEnter={() => setSubMenu(url)} onMouseLeave={() => setSubMenu('/')}>{text}</p>
      { components && subMenu === url &&
        <div className="absolute top-5 pt-3" onMouseEnter={() => setSubMenu(url)} onMouseLeave={() => setSubMenu('/')}>
          <div className='flex-col justify-center items-center list-none min-w-40'>
            {components.map(( comp, idx ) => (
              <div
                key={idx}
                className={ TitleStyle(comp.url) }
                onClick={ () => onClickMenu(comp.url)}>
                {comp.text}</div>
            ))}
          </div>
        </div>
      }
    </div>
  ), [ TitleStyle, setSubMenu, subMenu ]);

  return (
    <header className="grid grid-cols-3 z-50 w-full">
      <a href="/" className="inline-block mt-5 ml-7" aria-label="Cruip">
        <img className="w-14 h-14" src={Logo} alt="logo" />
      </a>
      <div className="flex gap-10 md:gap-20 justify-center items-center list-none z-50">
        <NavComponent url="/about" text="About" 
          components={[
            { url: "/about", text: "Our Team" }
          ]}/>
        {/* <NavComponent url="/product" text="Product"
          components={[
            { url: "/product/synthy", text: "SYNTHY" },
            { url: "/product/gilmourbar", text: "GilmourBar" },
          ]}
        /> */}
        <NavComponent url="/partners" text="Partners" />
        {/* <NavComponent url="/pricing" text="Pricing" />
        <NavComponent url="/blog" text="Blog" /> */}
      </div>
      {/* <div className="flex justify-end items-center mr-10">
        <p className={TitleStyle('/login')} onClick={() => onClickMenu('/login')}>Login</p>
      </div> */}
    </header>
  )
}
